import axios from "axios";
import JsCookie from "js-cookie";

let isRefreshing = false;
let tokenHelper = null;

//guarda la lista de peticiones que se hicieron en el refresh pero fallaron por que el token expiro, son funciones anonimas
//las que se guardan en este array.
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
    //console.log('onRefreshed = ', refreshSubscribers.length);
}

export class BaseService {
    http = axios.create({
        timeout: 50000, headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + this.buildToken()
        }, //baseURL: process.env.MIX_BASE_URL_JS,
        baseURL: '/',

    });

    url;

    //NO SE USA EN MI PROYECTO
    constructor(endPoint) {
        this.url = `${endPoint}`;
        this.http.interceptors.request.use((config) => config, (error) => Promise.reject(error));
        this.http.interceptors.response.use((response) => response, (error) => this.axiosResponse(error));
    }

    axiosResponse(error) {
        const {config, response: {status}} = error;
        const originalRequest = config;
        //const message = 'todo_refresh_token';

        //console.log(error.response.status, error.response.data.message);

        //error.response.statusText === "Unauthorized"
        //CAPTURAR QUE EL TOKEN YA FUNO.
        if (error.response.status === 401 && error.response.data.message === 'Token has expired') {

            //ES HACER UNA SOLA PETICION DE REFRESH TOKEN, YA QUE EN LA VISTA PUEDEN EXISTIR MAS DE 1 PETICION A LA BD
            // Y SOLO NECESITAMOS UNA SOLA PETICION DE REFRESH TOKEN
            if (!isRefreshing) {
                isRefreshing = true;
                //console.log('entrando a refresh token');

                //INVOCAMOS LA FUNCTION DEL REFRESH TOKE QUE MANDA LLAMAR UNA URL DEL API DONDE SE GENERA UN NUEVO TOKEN
                this.refreshToken().then(response => {
                    //isRefreshing = false;
                    //console.log('refreshing token = ', response.data.token);
                    onRefreshed(response.data.token);
                });
            }
            if (tokenHelper === null) {
                //GUARDAMOS EN UN ARRAY LAS PETICIONES QUE SE HACEN, VA A DEJAR EN UNA LISTA DE ESPERA EL TOKEN REFRESH
                //console.log('1- Entrando al NEW PROMISE');
                return new Promise((resolve, reject) => {
                    //console.log('2- Entrando al NEW PROMISE');
                    subscribeTokenRefresh((token) => {
                        tokenHelper = token;
                        //console.log('peticiones');

                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        this.http.defaults.headers['Authorization'] = 'Bearer' + token;
                        resolve(axios(originalRequest));
                    });
                });
            } else {
                //console.log('ELSE 4- Entrando al NEW PROMISE', tokenHelper);
                originalRequest.headers['Authorization'] = 'Bearer ' + tokenHelper;
                return axios(originalRequest);
            }

        } else {
            //console.log('Esto no esta funcionando');
        }

        return Promise.reject(error);
    }

    buildToken() {
        let _token = JsCookie.get('77f58f9ffd') + '.' + JsCookie.get('b93cb685d') + '.' + JsCookie.get('177d5affca2');
        console.log('token = ', _token);
        return _token;

    }

    refreshToken() {
        return this.http.post('api/refresh', {token: this.buildToken()})
    }

    getMany(params = {}) {
        return this.http.get(`${this.url}`, {params}).then(response => response.data).catch(err => err.message);
    }

    getOne(id) {
        return this.http.get(`${this.url}/${id}`).then(response => response.data).catch(err => err.message);
    }

    saveOne(object, options = {}) {
        let config = {};
        let {
            isForm = false, onUploadProgress = (e) => {
            }
        } = options;
        if (isForm === true) {
            config = {
                headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress
            }
        }
        return this.http.post(`${this.url}`, object, config).then(response => response.data).catch(err => err.message);
    }

    updateOne(id, object, options = {}) {
        let config = {};
        let {
            isForm = false, onUploadProgress = (e) => {
            }
        } = options;

        if (isForm === true) {
            object.append("_method", 'PATCH');
            config = {
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data', 'enctype': 'multipart/form-data'},
                onUploadProgress
            }
            return this.http({
                method: "post", url: `${this.url}/${id}`, data: object, headers: config.headers
            }).then(response => response.data).catch(err => err.message);
        } else {
            return this.http.patch(`${this.url}/${id}/`, object, config).then(response => response.data).catch(err => err.message);
        }

    }

    deleteOne(id) {
        return this.http.delete(`${this.url}/${id}`).then(response => response.data).catch(err => err.message);
    }

}


